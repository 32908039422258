import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { EMPLOYER_VACANCIES } from 'lib/queries';
import { useRouter } from 'next/router';
import mapElasticJobPostings from 'lib/mapElasticJobPostings';

export default function useEmployerVacancies({
  initVacancies = [],
  first = process.env.NEXT_PUBLIC_PAGINATION_ITEMS,
  initTotal,
  pageInfo,
  city,
  id,
  scrollToRef,
}) {
  const { query, ...router } = useRouter();
  const [vacancies, setVacancies] = useState(initVacancies);
  const [currentPage, setCurrentPage] = useState(1);
  const [startCursor, setStartCursor] = useState(pageInfo?.startCursor);
  const [endCursor, setEndCursor] = useState(pageInfo?.endCursor);
  const [employerVacanciesQuery, { loading }] = useLazyQuery(
    EMPLOYER_VACANCIES,
    {
      variables: {
        id,
        first,
        before: startCursor || null,
        after: endCursor || null,
      },
    }
  );

  const totalPages = Math.ceil(initTotal / first);

  const handleScrollTo = () => {
    // Timeout to ensure the scrollToRef is available
    setTimeout(
      () =>
        window.scrollTo({
          top: (scrollToRef?.current?.offsetTop || 0) - 24,
          behavior: 'smooth',
        }),
      0
    );
  };

  const handleRoute = page => {
    router.push(
      {
        path: router.path,
        query: { ...query, page },
      },
      null,
      { shallow: true, scroll: false }
    );
  };

  const fetchMore = ({ variables }) =>
    employerVacanciesQuery({
      variables: {
        id,
        first: parseInt(process.env.NEXT_PUBLIC_PAGINATION_ITEMS),
        ...variables,
      },
    }).then(({ data }) => {
      setVacancies(data?.companyBranch?.vacancies?.edges);
      setStartCursor(data?.companyBranch?.vacancies?.pageInfo?.startCursor);
      setEndCursor(data?.companyBranch?.vacancies?.pageInfo?.endCursor);
    });

  const handleNextPage = () => {
    const updatedCurrentPage = currentPage + 1;
    setCurrentPage(updatedCurrentPage);

    fetchMore({
      variables: {
        before: null,
        after: endCursor,
      },
    });
    handleRoute(updatedCurrentPage);
    handleScrollTo();

    return {
      after: endCursor,
      currentPage: updatedCurrentPage,
    };
  };

  const handlePrevPage = () => {
    const updatedCurrentPage = currentPage - 1;
    setCurrentPage(updatedCurrentPage);

    const before = updatedCurrentPage !== 1 ? startCursor : null;
    fetchMore({
      variables: {
        after: null,
        before,
      },
    });
    handleRoute(updatedCurrentPage);
    handleScrollTo();

    return {
      before,
      currentPage: updatedCurrentPage,
    };
  };

  useEffect(() => {
    if (initVacancies.length) {
      setVacancies(initVacancies);
      return;
    }
    // Fetch vacancies from the same city if there are no employer vacancies
    if (!city) return;
    const fetchCityVacancies = async () => {
      await fetch(`/api/browse/place?place=${city}`)
        .then(res => res.json())
        .then(data => {
          setVacancies(mapElasticJobPostings(data.results));
        });
    };
    fetchCityVacancies();
  }, [id]);

  return {
    vacancies,
    currentPage,
    totalPages,
    handleNextPage,
    handlePrevPage,
    loading,
  };
}
