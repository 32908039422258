import PropTypes from 'prop-types';
import Card from 'components/Card';
import { useTranslation } from 'next-i18next';

const EmployerSidebar = ({ employerName, motivation }) => {
  const { t } = useTranslation();

  if (!employerName || !motivation) return null;

  return (
    <Card extraClasses="c-sidebar__card">
      <h2>{t('employerPage.motivation', { name: employerName })}</h2>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: motivation }}
        className="u-list-check"
      />
    </Card>
  );
};

EmployerSidebar.propTypes = {
  employerName: PropTypes.string,
  motivation: PropTypes.string,
};

export default EmployerSidebar;
