import PropTypes from 'prop-types';

const formatPhoneNumber = phoneNumber =>
  phoneNumber.replace(/[()\s-]/g, '').replace('+', '00');

const PhoneNumber = ({ phoneNumber }) => (
  <a
    href={`tel:${formatPhoneNumber(phoneNumber)}`}
    target="_blank"
    rel="noreferrer"
  >
    {phoneNumber}
  </a>
);

PhoneNumber.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
};

export default PhoneNumber;
