import { Fragment, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import Layout from 'components/Layout';
import Breadcrumbs from 'components/Breadcrumbs';
import CompanyLogo from 'components/CompanyLogo';
import DisplayDate from 'components/DisplayDate';
import HeroImage from 'components/HeroImage';
import EmployerSidebar from 'components/EmployerSidebar';
import { useCompanyWebsite } from 'utils';
import PhoneNumber from 'components/PhoneNumber';
import JobPostingList from 'components/JobPostingList';
import YoutubeVideo from 'components/YoutubeVideo';
import Pagination from 'components/Pagination';
import useEmployerVacancies from 'utils/useEmployerVacancies';
import PropTypes from 'prop-types';
import CompanyBranchRichData from './CompanyBranchRichData';
import RelatedCompanyBranches from './RelatedCompanyBranches';

function CompanyBranchDetail({ companyBranch }) {
  const { t } = useTranslation();
  const scrollToRef = useRef(null);
  const { websiteSlug, websiteIsActive } = useCompanyWebsite({
    id: companyBranch?.id,
  });

  const {
    id,
    detailBackgroundImage,
    foundedAt,
    kvkName,
    kvkNumber,
    logo,
    numberOfEmployees,
    name,
    phoneNumber,
    address: {
      street,
      houseNumber,
      houseNumberSuffix,
      zipcode,
      city,
      state,
      country,
    },
    description,
    employerMotivation,
    vacancies: { edges: initVacancies, totalCount: initTotalCount, pageInfo },
    company,
    youtubeId,
    slug,
    relatedCompanyBranches,
  } = companyBranch;
  const {
    branches: { totalCount: numberOfRelatedCompanyBranches },
  } = company;

  const {
    vacancies,
    currentPage,
    totalPages,
    handleNextPage,
    handlePrevPage,
    loading,
  } = useEmployerVacancies({
    initVacancies,
    initTotal: initTotalCount,
    pageInfo,
    city,
    id,
    scrollToRef,
  });
  const { name: category } = companyBranch.category || {};

  return (
    <Layout
      title={t('employerPage.pageTitle', { name })}
      metaDescription={t('employerPage.pageDescription', { name, city })}
      canonical={
        websiteSlug && websiteIsActive
          ? `https://${websiteSlug}${process.env.WHITELABEL_ENDPOINT}`
          : ''
      }
    >
      {/* hero */}
      <HeroImage image={detailBackgroundImage} />
      <div className="o-retain o-retain--wall c-employer-page">
        {/* rich data */}
        <CompanyBranchRichData companyBranch={companyBranch} />

        {/* crumbs */}
        <Breadcrumbs
          links={[
            {
              label: t('jobPostingOverview.pageTitle'),
              href: '/vacatures',
            },
            {
              label: t('employerPage.breadcrumb', { name }),
              href: `/vacatures/${slug}`,
            },
          ]}
        />
        <div className="c-sidebar-layout">
          <div>
            <h1 className="u-margin-bottom-none">
              {t('employerPage.pageHeading', { name })}
            </h1>
            {/* address */}
            <div className="c-employer-page__address">
              {`${street} ${houseNumber}${
                houseNumberSuffix || ''
              }, ${zipcode} ${city}, ${state ? `${state}, ` : ''}${country}`}
            </div>
            {/* logo  */}
            <CompanyLogo
              company={{ logo, name }}
              extraClasses="u-margin-top-large u-margin-bottom-large"
            />
            {/* info */}
            <h2 className="u-visually-hidden">
              {t('employerPage.profile', { name })}
            </h2>
            <dl className="c-employer-page__summary">
              {[
                {
                  label: 'category',
                  value: category,
                },
                {
                  label: 'locations',
                  value: numberOfRelatedCompanyBranches,
                },
                {
                  label: 'numberOfEmployees',
                  value: numberOfEmployees,
                },
                {
                  label: 'founded',
                  value: foundedAt ? <DisplayDate date={foundedAt} /> : null,
                },
                {
                  label: 'registeredName',
                  value: kvkName,
                },
                {
                  label: 'registeredNumber',
                  value: kvkNumber,
                },
                {
                  label: 'phone',
                  value: phoneNumber ? (
                    <PhoneNumber phoneNumber={phoneNumber} />
                  ) : null,
                },
              ]
                .filter(({ value }) => !!value)
                .map(({ label, value }) => (
                  <Fragment key={label}>
                    <dt>{t(`employerPage.employerInfo.${label}`)}:</dt>
                    <dd>{value}</dd>
                  </Fragment>
                ))}
            </dl>
            {/* about */}
            <h2 className="u-margin-top-large h3">
              {t('employerPage.about', { name })}
            </h2>
            <div
              className="u-margin-bottom-base"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: description }}
            />

            {youtubeId && <YoutubeVideo youtubeId={youtubeId} />}

            {/* job postings */}
            {!!vacancies && !!vacancies.length && (
              <>
                <h2 className="u-margin-top-large h3">
                  {t(
                    initVacancies.length
                      ? 'employerPage.jobPostings'
                      : 'employerPage.jobPostingsCity',
                    { name, city }
                  )}
                </h2>
                <span ref={scrollToRef} />

                <JobPostingList
                  jobPostings={vacancies}
                  skipFetchExtraData={!!initVacancies.length}
                />

                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPrev={handlePrevPage}
                  onNext={handleNextPage}
                  loading={loading}
                />
              </>
            )}
          </div>
          {/* This container div is needed to make the position sticky work on the sidebar */}
          <div>
            <div className="c-sidebar">
              <EmployerSidebar
                employerName={name}
                motivation={employerMotivation}
              />

              {!!relatedCompanyBranches.length && (
                <RelatedCompanyBranches
                  relatedCompanyBranches={relatedCompanyBranches}
                  companyName={company.name}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

CompanyBranchDetail.propTypes = {
  companyBranch: PropTypes.shape({
    id: PropTypes.string.isRequired,
    detailBackgroundImage: PropTypes.string,
    foundedAt: PropTypes.string,
    kvkName: PropTypes.string,
    kvkNumber: PropTypes.string,
    logo: PropTypes.object,
    numberOfEmployees: PropTypes.number,
    name: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string,
    address: PropTypes.shape({
      street: PropTypes.string.isRequired,
      houseNumber: PropTypes.number.isRequired,
      houseNumberSuffix: PropTypes.string,
      zipcode: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string,
      country: PropTypes.string.isRequired,
    }).isRequired,
    description: PropTypes.string.isRequired,
    employerMotivation: PropTypes.string,
    vacancies: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.object,
        })
      ),
      totalCount: PropTypes.number,
      pageInfo: PropTypes.object,
    }),
    company: PropTypes.shape({
      name: PropTypes.string.isRequired,
      branches: PropTypes.shape({
        totalCount: PropTypes.number,
      }),
    }),
    youtubeId: PropTypes.string,
    slug: PropTypes.string.isRequired,
    relatedCompanyBranches: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
      })
    ),
  }),
};

export default CompanyBranchDetail;
