function CompanyBranchRichData({ companyBranch }) {
  const { detailBackgroundImage, logo, name, phoneNumber, address, slug } =
    companyBranch;

  const url = `${process.env.ENVIRONMENT_URL}/vacatures/${slug}`;
  const richData = {
    '@id': url,
    '@context': 'https://schema.org',
    '@type': 'Organization',
    logo: [
      logo?.contentUrl,
      detailBackgroundImage?.public &&
      detailBackgroundImage?.image &&
      detailBackgroundImage?.contentUrl
        ? detailBackgroundImage.contentUrl
        : null,
    ].filter(img => !!img),
    name,
    address: {
      '@type': 'PostalAddress',
      streetAddress: `${address.street} ${address.houseNumber}${
        address.houseNumberSuffix || ''
      }`,
      addressLocality: address.city,
      addressRegion: address.state,
      postalCode: address.zipcode,
      addressCountry: address.country,
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: address.latitude || null,
      longitude: address.longitude || null,
    },
    url,
    telephone: phoneNumber || null,
  };

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(richData),
      }}
    />
  );
}

export default CompanyBranchRichData;
