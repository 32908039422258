import { print } from 'graphql';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import hasAuthToken from 'lib/hasAuthToken';
import { EMPLOYER } from 'lib/queries';
import CompanyBranchDetail from '@organisms/CompanyBranchDetail';
import { getStrippedEntityId } from 'lib/entityIds';

function CompanyBranchPage({ companyBranch }) {
  return <CompanyBranchDetail companyBranch={companyBranch} />;
}

export async function getServerSideProps({ req, res, locale, query }) {
  if (!hasAuthToken(req)) {
    res.setHeader(
      'Cache-Control',
      'public, max-age=300, s-maxage=300, stale-while-revalidate=600'
    );
  }

  const { slug } = query;

  return fetch(process.env.GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: print(EMPLOYER),
      variables: {
        slug,
        first: parseInt(process.env.NEXT_PUBLIC_PAGINATION_ITEMS),
      },
    }),
  })
    .then(async response => {
      if (!response.ok) {
        return {
          notFound: true,
        };
      }
      const { data } = await response.json();
      const companyBranch = data?.companyBranches?.edges[0]?.node;

      if (!companyBranch) {
        return {
          notFound: true,
        };
      }

      // Fetch related company branches from elastic search
      const companyBranches = await fetch(
        `${
          process.env.NEXT_API_ROUTE
        }/elastic/related-company-branches?companyId=${getStrippedEntityId(
          companyBranch.company.id
        )}`
      ).then(resp => resp.json());
      const relatedCompanyBranches =
        companyBranches?.results
          .map(branch => ({
            ...branch._source,
            count: branch._source.cachedActiveVacancies,
          }))
          .filter(branch => branch.slug !== slug && branch.count > 0) || [];

      return {
        props: {
          companyBranch: {
            ...companyBranch,
            slug,
            relatedCompanyBranches,
          },
          ...(await serverSideTranslations(locale, ['common'])),
        },
      };
    })
    .catch(() => ({
      notFound: true,
    }));
}

export default CompanyBranchPage;
