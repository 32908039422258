import PropTypes from 'prop-types';
import Image from 'next/image';

const HeroImage = ({ image }) => {
  if (image?.public && image?.image && image?.contentUrl) {
    return (
      <div className="c-hero">
        <Image
          src={image.contentUrl}
          layout="fill"
          className="c-hero__image"
          objectFit="cover"
          priority
        />
      </div>
    );
  }
  return null;
};

HeroImage.propTypes = {
  image: PropTypes.shape({
    image: PropTypes.bool,
    public: PropTypes.bool,
    contentUrl: PropTypes.string,
  }),
};

export default HeroImage;
